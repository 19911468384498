const data = {
  Accessories: {
    Agile_Bevor: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Ailette: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Arcane_Collar: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Amulet: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Apron: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Archfiend_Horns: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Banded_Schynbalds: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Bangle: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Avian_Wing: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Bevor: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Blindfold: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Avian_Visor: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Brawler_Gauntlets: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Chamion_Belt: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Carriage_Cloak: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png'],
    Chaps: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Chausses: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Chest_Hair: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Chlamys: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Circlet: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Couter: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Cross_Brace: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Cruel_Gauntlet: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Crusader_Standard: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Cultist_Shawl: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Decorative_Plumage: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Demonic_Spaulders: ['blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Demon_Horns: ['black.png', 'blue.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Devil_Horns: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Desert_Scarf: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Drawn_Hood: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Duelist_Cloak: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Eastern_Circlet: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Elite_Belt: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Face_Scarf: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Epaulettes: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Fauld: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Falconer_Glove: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Faun_Horns: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Fools_Garland: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Fleshy_Horns: [
      'black.png',
      'blue.png',
      'brick.png',
      'bronze.png',
      'brown.png',
      'caucasian.png',
      'chocolate.png',
      'dust.png',
      'gold.png',
      'green.png',
      'grey.png',
      'honey.png',
      'night.png',
      'olive.png',
      'pale.png',
      'porcelain.png',
      'purple.png',
      'red.png',
      'swarthy.png',
      'tan.png',
    ],
    Fur_Collar: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Galerus: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Garish_Feather: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Heavy_Pauldrons: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Grim_Sash: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Imp_Horns: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Jewelled_Gorget: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    klappvisor: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Leather_Cape: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Manica: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Mountain_Pauldrons: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Monstrous_Arm: [
      'black.png',
      'blue.png',
      'brick.png',
      'bronze.png',
      'brown.png',
      'caucasian.png',
      'chocolate.png',
      'dust.png',
      'gold.png',
      'green.png',
      'grey.png',
      'honey.png',
      'night.png',
      'olive.png',
      'pale.png',
      'porcelain.png',
      'purple.png',
      'red.png',
      'swarthy.png',
      'tan.png',
    ],
    Necromancer_Shawl: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Ornate_Besagew: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Outsider_Cloak: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Pelt_Shawl: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Owl_Cloak: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Pickpocket_Gloves: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Plate_Gauntlets: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Plate_Gorget: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Poleyn: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png'],
    Phallus: [
      'black.png',
      'blue.png',
      'brick.png',
      'bronze.png',
      'brown.png',
      'caucasian.png',
      'chocolate.png',
      'dust.png',
      'gold.png',
      'green.png',
      'grey.png',
      'honey.png',
      'night.png',
      'olive.png',
      'pale.png',
      'porcelain.png',
      'purple.png',
      'red.png',
      'swarthy.png',
      'tan.png',
    ],
    Ram_Horns: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Seeker_Kalkriese: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Rounded_Spaulder: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Senator_Sash: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Shaman_Cloak: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Shaman_Skull: [
      'Black.png',
      'Blue.png',
      'Brown.png',
      'Chief_Black.png',
      'Chief_Blue.png',
      'Chief_Brown.png',
      'Chief_Gold.png',
      'Chief_Green.png',
      'Chief_Ivory.png',
      'Chief_Night.png',
      'Chief_Red.png',
      'Chief_Tan.png',
      'Chief_White.png',
      'Gold.png',
      'Green.png',
      'Ivory.png',
      'Night.png',
      'Red.png',
      'Tan.png',
      'White.png',
    ],
    Sharp_Horns: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Shoulder_Harness: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Shawl: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Silk_Belt: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Silk_Headband: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Silk_Scarf: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Skull: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Slotted_Visor: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Studded_Bracer: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Succubus_Wings: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Tatters: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Underworld_Horns: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Vented_Kalkriese: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Violent_Greaves: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Swamp_Cloak: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Waistband: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Wide_Belt: ['black.png', 'blue.png', 'brown.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Thief_Cowl: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Winged_Crown: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Wing_Adornment: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Wing_Pauldrons: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Wreath: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
  },
  Arms: {
    Bare_Arm: [
      'black.png',
      'blue.png',
      'brick.png',
      'bronze.png',
      'brown.png',
      'caucasian.png',
      'chocolate.png',
      'dust.png',
      'gold.png',
      'green.png',
      'grey.png',
      'honey.png',
      'night.png',
      'olive.png',
      'pale.png',
      'porcelain.png',
      'purple.png',
      'red.png',
      'swarthy.png',
      'tan.png',
    ],
  },
  Face: {
    Angry_Eyebrows: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Antennae: ['black.png', 'blue.png', 'bronze.png', 'brown.png', 'gold.png', 'green.png', 'grey.png', 'night.png', 'pale.png', 'purple.png', 'red.png', 'tan.png'],
    Arcane_Beard: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Anchor: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Aristocrat_Mustache: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Bestial_Ancestory: ['Minotaur.png', 'Naga.png'],
    Avian_Mask: ['Cormorant.png', 'Eagle.png', 'Falcon.png', 'Heron.png', 'Owl.png', 'Raven.png', 'Vulture.png'],
    Bushy_Goatee: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Bushy_Beard: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Carnival_Mask: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Cavalier_Mustache: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Chieftan_Beard: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Debonair: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Determined_Eyebrows: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Cyclops_Eye: [
      'black.png',
      'blue.png',
      'brick.png',
      'bronze.png',
      'brown.png',
      'caucasian.png',
      'chocolate.png',
      'dust.png',
      'gold.png',
      'green.png',
      'grey.png',
      'honey.png',
      'night.png',
      'olive.png',
      'pale.png',
      'porcelain.png',
      'purple.png',
      'red.png',
      'swarthy.png',
      'tan.png',
    ],
    Disc_Earrings: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Dwarvish_Braids: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Eye_Color: ['blue.png', 'brown.png', 'gold.png', 'green.png', 'grey.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Elf_Ear: [
      'black.png',
      'blue.png',
      'brick.png',
      'bronze.png',
      'brown.png',
      'caucasian.png',
      'chocolate.png',
      'dust.png',
      'gold.png',
      'green.png',
      'grey.png',
      'honey.png',
      'night.png',
      'olive.png',
      'pale.png',
      'porcelain.png',
      'purple.png',
      'red.png',
      'swarthy.png',
      'tan.png',
    ],
    Full_Beard: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Goatee: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Goddess_Eyeliner: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Goggles: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Genie_Beard: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Imperial: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Haunting_Mask: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Mask: ['black.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Monocle: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Mouths: ['fangs.png', 'grin.png', 'neutral.png', 'screaming.png', 'serrated.png', 'teeth.png', 'tusks.png'],
    Mercenary_Eyepatch: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Moxa_Burns: ['Crown.png', 'Full_Head.png', 'Grid.png', 'Ring.png', 'Six.png', 'Triratna.png', 'Two_Rows.png'],
    Mustache: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Mutton_Chops: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Mystic_Identity: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Occular_Implant: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Oracle_Facepaint: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Philosopher_Beard: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Pirate_Beard: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Pirate_Eyepatch: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Plague_Mask: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Pointed_Ear: [
      'black.png',
      'blue.png',
      'brick.png',
      'Bronze.png',
      'brown.png',
      'caucasian.png',
      'chocolate.png',
      'dust.png',
      'gold.png',
      'green.png',
      'grey.png',
      'honey.png',
      'night.png',
      'olive.png',
      'pale.png',
      'porcelain.png',
      'purple.png',
      'red.png',
      'swarthy.png',
      'tan.png',
    ],
    Postiche: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Precision_Beard: ['black.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Politician_Chin: [
      'black.png',
      'blue.png',
      'brick.png',
      'bronze.png',
      'brown.png',
      'caucasian.png',
      'chocolate.png',
      'dust.png',
      'gold.png',
      'green.png',
      'grey.png',
      'honey.png',
      'night.png',
      'olive.png',
      'pale.png',
      'porcelain.png',
      'purple.png',
      'red.png',
      'swarthy.png',
      'tan.png',
    ],
    Printer_Spectacles: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Royal_beard: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Satyr_Ear: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Shadowy_Countenance: ['black.png', 'blue.png', 'green.png', 'red.png'],
    Shepherd_Beard: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Sheriff_Whiskers: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Stoic_Mask: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Sideburns: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Stubble: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Spectacles: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Tinted_Spectacles: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Tribal_Paint: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Tribal_Mask: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Vigilante_Lenses: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    War_Paint: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Western: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Wounds: ['Chin.png', 'Eye_Gouge.png', 'Horizontal.png', 'Skull.png', 'Vertical.png'],
  },
  Feet: {
    Anklet: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Arcane_Slippers: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Casual_Shoes: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Collared_Boots: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Crakow: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Gladiator_Sandals: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Halfling_Feet: [
      'black.png',
      'blue.png',
      'brick.png',
      'bronze.png',
      'brown.png',
      'caucasian.png',
      'chocolate.png',
      'dust.png',
      'gold.png',
      'green.png',
      'grey.png',
      'honey.png',
      'night.png',
      'olive.png',
      'pale.png',
      'porcelain.png',
      'purple.png',
      'red.png',
      'swarthy.png',
      'tan.png',
    ],
    Jewelled_Boots: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Poulaine: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Leg_Wraps: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Sandals: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Spiked_Boots: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Tall_Boots: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
  },
  Hair: {
    Academic: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Advisor: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Arcanist: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Artist: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Archivist: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Author: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Baker: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Banker: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Barbarian: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Beautician: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Beserker: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Beggar: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Bon_Vivant: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Bumpkin: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Cadet: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Chauffeur: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Clown: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Champion: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Composer: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Concierge: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Conspirator: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Dancer: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Duchess: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Elder: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Empath: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png'],
    Entertainer: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Equestrian: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Fashionista: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Fencer: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Farmer: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Florist: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Gent: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Goddess: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Gorgon_Tangle: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Guide: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Hermit: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'hermit.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Infantry: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Judge: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Knight: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Idol: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Legend: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Louse: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'greeb.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Maiden: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Mercenary: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Matron: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Miser: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Monk: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Mourner: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Nymph: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Offender: ['black.png', 'blue.png', 'brown.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Performer: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Pharaoh: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Priestess: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Prince: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Prowler: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Rebel: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Royal: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Poet: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Runner: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Saint: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Seamstress: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'tan.png', 'white.png'],
    Seeker: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Sergeant: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Sheriff: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Sellsword: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'grey.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Socialite: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Soldier: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Sovereign: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Spinster: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Sprite: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Student: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Swashbuckler: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Swindler: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Thug: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Trailblazer: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Treasure_Hunter: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Youth: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Villian: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Wishmaster: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
  },
  Hats: {
    Adept_Hood: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Adventurer_Cap: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Armet: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Assassin_Mask: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Aventail: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Barbute: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'tan.png', 'white.png'],
    Bascinet: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Bashlyk: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Beret: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Broe_Helm: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Chainmail_Hood: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Burqa: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Combatant_Helm: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Confessor: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Convict_Helm: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Corinthian: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Cultist_Hood: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Decorated_Burgonet: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Deity_Headress: ['Cow.png', 'Crocodile.png', 'Eagle.png', 'Hawk.png', 'Hippo.png', 'Ibis.png', 'Jackal.png', 'Lioness.png', 'Snake.png'],
    Deshret: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Diadem: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Doppi: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Dragonfiend: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Druid_Hood: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Dwarvish_Helm: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Embroidered_Hood: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Executioner_Hood: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'white.png'],
    Feathered_Cowl: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Fez: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Freighter_Cap: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Gardener_Bonnet: ['black.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Galea: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Gatekeeper_Helm: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Ghutra: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Gourmand_Cap: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Guard_Captain_Helm: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Great_Helm: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Habit: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Hedjet: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Hijab: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Field_Hat: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Horseman_Cap: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Imp_Cap: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Infantry_Skull_Cap: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Jester_Hat: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Kalpak: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'white.png'],
    Keffiyeh: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Joseon_Helm: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Khepresh: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Knight_Helm: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Lamellar: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Lunar_Cap: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'tan.png', 'white.png'],
    Mage_Hat: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Mingrelian_Headscarf: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Morion: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Nemean_Hood: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Nemes: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Pelt: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Parade_Helm: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Papakha: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Petasos: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Produce_Helm: ['Apple.png', 'Cabbage.png', 'Eggplant.png', 'Jack_O_Lantern.png', 'Watermelon.png'],
    Pschent: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Pirate_Bandana: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Quail_Cap: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Ram_Knight: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Renaissance_Helm: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Rivetted_Stechhelm: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Sallet: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Segmented_Helm: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Society_Veil: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Sorcerer_Cap: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Squire_Visor: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Spartan_Helm: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Sultan_Cap: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Thief_Hood: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Trickster_Hat: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Troubador_Beret: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Turban: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Vigilante_Hat: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Warrior_Crown: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Winged_Helm: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Underworld_Helm: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
  },
  Lower: {
    Adept_Robes: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Athletic_Shorts: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Barbarian_Rags: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Beastial_Ancestry: ['Harpy.png', 'Merfolk.png', 'Naga.png', 'Satyr.png'],
    Belted_Slacks: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Ceremonial_Gown: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Champion_Tights: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Codpiece: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Combatant_Wrap: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Cultist_Sash: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Cutoffs: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Druid_Robe: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Exotic_Swimwear: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Hag_Skirt: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Himation: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Kilt: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Loincloth: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Merchant_Slacks: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Mystic_Robe: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Miner_Pants: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Necromancer_Tatters: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Overalls: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Phaoroh_Cloth: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Sarong: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Regal_Sash: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Savage_Skirt: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Shendyt: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Skeletal_Leggings: ['blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Stargazer_Gown: ['black.png', 'blue.png', 'brown.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Summoner_Skirt: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Trainee_Pants: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'orange.png', 'red.png', 'tan.png', 'white.png'],
    Skirt: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Trousers: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Swindler_Robes: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Underclothes: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Witch_Dress: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Waist_Wrap: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
  },
  Off_Hand: {
    Archmage_Staff: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Bindle: ['black.png', 'blue.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Giant_Club: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Broad_Sword: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Hefty_Branch: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Katana: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Mystery_Sack: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Necromancer_Staff: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Scythe: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Severed_Head: [
      'black.png',
      'blue.png',
      'brick.png',
      'bronze.png',
      'brown.png',
      'caucasian.png',
      'chocolate.png',
      'dust.png',
      'gold.png',
      'green.png',
      'grey.png',
      'honey.png',
      'night.png',
      'olive.png',
      'pale.png',
      'porcelain.png',
      'purple.png',
      'red.png',
      'swarthy.png',
      'tan.png',
    ],
    Shepherd_Crook: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Wicked_Staff: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
  },
  Outfits: {
    Bathing_Suit: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Celebrant_Robes: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Chiton: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Bikini: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Chokha: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Cossock: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Cultist_Robe: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Dujeong_Gap: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Destitute_Barrel: ['brown.png'],
    Huntress_Gown: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Martial_Artist_Jumper: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'orange.png', 'red.png', 'tan.png', 'white.png'],
    Ornate_Deel: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Peplos: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Pious_Gown: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Scholar_Toga: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Silk_Slip: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Teacher_Gown: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Thawb: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Taraz: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Unitard: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
  },
  Sleeves: {
    Bracer: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Flowing_Sleeve: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Short_Sleeve: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Long_Sleeve: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    None: ['none.png'],
  },
  Upper: {
    Agility_Wrap: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Alchemy_Coat: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Ale_Belly: [
      'black.png',
      'blue.png',
      'brick.png',
      'bronze.png',
      'brown.png',
      'caucasian.png',
      'chocolate.png',
      'dust.png',
      'gold.png',
      'green.png',
      'grey.png',
      'honey.png',
      'night.png',
      'olive.png',
      'pale.png',
      'porcelain.png',
      'purple.png',
      'red.png',
      'swarthy.png',
      'tan.png',
    ],
    Arming_Doublet: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Bare_Breasts: [
      'black.png',
      'blue.png',
      'brick.png',
      'bronze.png',
      'brown.png',
      'caucasian.png',
      'chocolate.png',
      'dust.png',
      'gold.png',
      'green.png',
      'grey.png',
      'honey.png',
      'night.png',
      'olive.png',
      'pale.png',
      'porcelain.png',
      'purple.png',
      'red.png',
      'swarthy.png',
      'tan.png',
    ],
    Beggar_Rags: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Beserker_Chestpiece: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Blacksmith_Smock: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Bone_Plate: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Brassiere: ['blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Brawler_Vest: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Breastplate: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Brigandine: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Brute_Strength: [
      'black.png',
      'blue.png',
      'brick.png',
      'bronze.png',
      'brown.png',
      'caucasian.png',
      'chocolate.png',
      'dust.png',
      'gold.png',
      'green.png',
      'grey.png',
      'honey.png',
      'night.png',
      'olive.png',
      'pale.png',
      'porcelain.png',
      'purple.png',
      'red.png',
      'swarthy.png',
      'tan.png',
    ],
    Buccaneer_Coat: ['black.png', 'blue.png', 'gold.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Buff_Coat: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Captain_Coat: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Crusader_Tabard: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Embroidered_Tunic: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Embroidered_Vest: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Feathered_Breastplate: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'red.png', 'tan.png', 'white.png'],
    Hauberk: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Hidden_Twin: [
      'black.png',
      'blue.png',
      'brick.png',
      'bronze.png',
      'brown.png',
      'caucasian.png',
      'chocolate.png',
      'dust.png',
      'gold.png',
      'green.png',
      'grey.png',
      'honey.png',
      'night.png',
      'olive.png',
      'pale.png',
      'porcelain.png',
      'purple.png',
      'red.png',
      'swarthy.png',
      'tan.png',
    ],
    Gambeson: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Huntress_Smock: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Laboratory_Robe: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Kuyak: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Lorica: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Mercenary_Mail: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Mountain_Tunic: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Light_Platemail: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Noble_Blouse: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Open_Robe: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Padded_Coat: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Peasant_Shirt: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Plate_Coat: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Poncho: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Serpent_Mail: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Skeleton_Suit: ['blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Royal_Caplet: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Sorcery_Coat: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Swashbuckler_Blouse: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Tailored_Suit: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Thug_vest: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Thick_Fur_Coat: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'red.png', 'tan.png', 'white.png'],
    Trainee_Tunic: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
    Trenchcoat: ['black.png', 'blue.png', 'brown.png', 'gold.png', 'green.png', 'ivory.png', 'night.png', 'red.png', 'tan.png', 'white.png'],
  },
}

export default data
